import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/docs/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DownloadLink = makeShortcode("DownloadLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "products-and-pricing-data"
    }}>{`Products and pricing data`}</h1>
    <p>{`Part of our mission statement is to share our data with anyone who wants it, for free. This page lists our core products and pricing data dumps which are updated on a monthly basis. All data dumps have permanent URL's and come as NeTEx. NeTEx is the official format for public transport data in Norway and is the most complete in terms of available data.`}</p>
    <h2 {...{
      "id": "authentication"
    }}>{`Authentication`}</h2>
    <p>{`Category: National journey planning. License: NLOD`}</p>
    <h2 {...{
      "id": "general-information"
    }}>{`General information`}</h2>
    <p>{`The richness of the data depends on the source data from the respective county or operator. All parties are required to submit their data in the NeTEx format, in accordance with the Norwegian NeTEX profile, but unfortunately, we're not there yet.`}</p>
    <h2 {...{
      "id": "format-specification"
    }}>{`Format specification`}</h2>
    <h3 {...{
      "id": "netex-and-norwegian-profile"
    }}>{`NeTEx and Norwegian profile.`}</h3>
    <p>{`NeTEx (Network and Timetable Exchange) is an XML-based format for sharing public transport data. It is a CEN-standard and is slated to become an EU standard for national datasets.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://netex-cen.eu/"
        }}>{`Read about NeTEx`}</a>{` (external)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://enturas.atlassian.net/wiki/spaces/PUBLIC/pages/728891481/Nordic+NeTEx+Profile"
        }}>{`Nordic NeTEx profile`}</a>{` defines all the elements, and when and how to use them.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://netex-cen.eu/?page_id=14"
        }}>{`White papers`}</a>{` (external)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/NeTEx-CEN/NeTEx"
        }}>{`XSD XML Schema`}</a>{` (external)`}</li>
    </ul>
    <p>{`The data is retrieved from internal API's which represents each datatype. For example are data in this export pulled out from the following internal Products API's:`}</p>
    <ul>
      <li parentName="ul">{`FareProduct`}</li>
      <li parentName="ul">{`FareStructureElement`}</li>
      <li parentName="ul">{`FareTable`}</li>
      <li parentName="ul">{`SalesPackage`}</li>
      <li parentName="ul">{`ValidableElement`}</li>
      <li parentName="ul">{`CompanionProfiles`}</li>
      <li parentName="ul">{`FrequencyOfUse`}</li>
      <li parentName="ul">{`PenaltyPolicy`}</li>
      <li parentName="ul">{`RoundTrip`}</li>
      <li parentName="ul">{`UsageValidityPeriod`}</li>
      <li parentName="ul">{`UserProfile`}</li>
      <li parentName="ul">{`GroupTicket`}</li>
      <li parentName="ul">{`GenericParameterAssignments`}</li>
      <li parentName="ul">{`Interchanging`}</li>
    </ul>
    <p>{`To see the whole picture of e.g. one specific salespackage or fareproduct, one has to combine data from several datatypes. Each datatype contains references to other relevant datatypes - it is possible to follow the references from e.g. SalesPackage to FareProduct to ValidableElement to FareStructureElement to FareTable. In this first version some references are not further described, this is e.g. the case for userprofiles, in the next version of this NeTEx export more datatypes will be included.`}</p>
    <h1 {...{
      "id": "fareframe"
    }}>{`FareFrame`}</h1>
    <a href={`/static/products/FareTables_ALL_formatted.xml`} download>
  FareTables ALL formatted
    </a>
    <h2 {...{
      "id": "basis-data"
    }}>{`Basis data`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Codespace`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Fare Product`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Fare Structure Element`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Sales Package`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Validable Element`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Companion Profile`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Frequency Of Use`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Penalty Policy`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Round Trip`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Usage Validity Period`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`User Profile`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Group Ticket`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Generic Parameter Assignment`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Interchanging`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AtB (Trøndelag)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ATB`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FareProducts_ATB_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FareStructureElements_ATB_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/SalesPackages_ATB_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/ValidableElements_ATB_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FrequenciesOfUse_ATB_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/UsageValidityPeriods_ATB_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/UserProfiles_ATB_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/GroupTickets_ATB_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/GenericParameterAssignments_ATB_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/Interchangings_ATB_formatted.xml`} mdxType="DownloadLink" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Brakar (Buskerud)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BRA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FareProducts_BRA_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FareStructureElements_BRA_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/SalesPackages_BRA_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/ValidableElements_BRA_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/CompanionProfiles_BRA_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FrequenciesOfUse_BRA_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/UsageValidityPeriods_BRA_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/UserProfiles_BRA_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/GroupTickets_BRA_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/GenericParameterAssignments_BRA_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/Interchangings_BRA_formatted.xml`} mdxType="DownloadLink" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Entur AS`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ENT`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FareProducts_ENT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/SalesPackages_ENT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/ValidableElements_ENT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/UserProfiles_ENT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/GroupTickets_ENT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Flåmsbana`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FLB`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FareProducts_FLB_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FareStructureElements_FLB_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/SalesPackages_FLB_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/ValidableElements_FLB_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FrequenciesOfUse_FLB_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/RoundTrips_FLB_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/UsageValidityPeriods_FLB_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/UserProfiles_FLB_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/GroupTickets_FLB_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/GenericParameterAssignments_FLB_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/Interchangings_FLB_formatted.xml`} mdxType="DownloadLink" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Vy (formerly NSB) Gjøvikbanen`}{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`GJB`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FareProducts_GJB_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FareStructureElements_GJB_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/SalesPackages_GJB_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/ValidableElements_GJB_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/CompanionProfiles_GJB_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FrequenciesOfUse_GJB_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/PenaltyPolicies_GJB_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/UsageValidityPeriods_GJB_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/UserProfiles_GJB_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/GroupTickets_GJB_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/GenericParameterAssignments_GJB_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/Interchangings_GJB_formatted.xml`} mdxType="DownloadLink" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Go Ahead`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`GOA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FareProducts_GOA_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FareStructureElements_GOA_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/SalesPackages_GOA_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/ValidableElements_GOA_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FrequenciesOfUse_GOA_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/PenaltyPolicies_GOA_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/UsageValidityPeriods_GOA_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/UserProfiles_GOA_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/GroupTickets_GOA_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/Interchangings_GOA_formatted.xml`} mdxType="DownloadLink" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Innlandet`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`INN`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FareProducts_INN_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FareStructureElements_INN_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/SalesPackages_INN_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/ValidableElements_INN_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/CompanionProfiles_INN_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FrequenciesOfUse_INN_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/UsageValidityPeriods_INN_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/UserProfiles_INN_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/GroupTickets_INN_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/GenericParameterAssignments_INN_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/Interchangings_INN_formatted.xml`} mdxType="DownloadLink" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Kolumbus (Rogaland)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`KOL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FareProducts_KOL_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/SalesPackages_KOL_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/ValidableElements_KOL_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/CompanionProfiles_KOL_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FrequenciesOfUse_KOL_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/UsageValidityPeriods_KOL_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/UserProfiles_KOL_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/GroupTickets_KOL_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Vy (formerly NSB)`}{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NSB`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FareProducts_NSB_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FareStructureElements_NSB_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/SalesPackages_NSB_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/ValidableElements_NSB_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/CompanionProfiles_NSB_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FrequenciesOfUse_NSB_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/PenaltyPolicies_NSB_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/UsageValidityPeriods_NSB_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/UserProfiles_NSB_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/GroupTickets_NSB_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/Interchangings_NSB_formatted.xml`} mdxType="DownloadLink" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Østfold kollektivtrafikk`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`OST`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FareProducts_OST_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FareStructureElements_OST_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/SalesPackages_OST_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/ValidableElements_OST_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/CompanionProfiles_OST_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FrequenciesOfUse_OST_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/UsageValidityPeriods_OST_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/UserProfiles_OST_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/GroupTickets_OST_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/GenericParameterAssignments_OST_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/Interchangings_OST_formatted.xml`} mdxType="DownloadLink" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Ruter (Oslo & Akershus)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`RUT`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FareProducts_RUT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FareStructureElements_RUT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/SalesPackages_RUT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/ValidableElements_RUT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/CompanionProfiles_RUT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FrequenciesOfUse_RUT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/PenaltyPolicies_RUT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/UsageValidityPeriods_RUT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/UserProfiles_RUT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/GroupTickets_RUT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/GenericParameterAssignments_RUT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/Interchangings_RUT_formatted.xml`} mdxType="DownloadLink" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`SJ NORD`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SJN`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FareProducts_SJN_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FareStructureElements_SJN_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/SalesPackages_SJN_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/ValidableElements_SJN_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FrequenciesOfUse_SJN_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/PenaltyPolicies_SJN_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/UsageValidityPeriods_SJN_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/UserProfiles_SJN_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/GroupTickets_SJN_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/GenericParameterAssignments_SJN_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/Interchangings_SJN_formatted.xml`} mdxType="DownloadLink" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Skyss (Hordaland)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SKY`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FareProducts_SKY_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FareStructureElements_SKY_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/SalesPackages_SKY_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/ValidableElements_SKY_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/CompanionProfiles_SKY_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FrequenciesOfUse_SKY_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/UsageValidityPeriods_SKY_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/UserProfiles_SKY_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/GroupTickets_SKY_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/GenericParameterAssignments_SKY_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/Interchangings_SKY_formatted.xml`} mdxType="DownloadLink" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Kringom (Sogn og Fjordane)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`SOF`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FareProducts_SOF_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/SalesPackages_SOF_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FrequenciesOfUse_SOF_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/UsageValidityPeriods_SOF_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/UserProfiles_SOF_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/GroupTickets_SOF_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/GenericParameterAssignments_SOF_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/Interchangings_SOF_formatted.xml`} mdxType="DownloadLink" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Tågkompaniet`}{`*`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TAG`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FareProducts_TAG_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FareStructureElements_TAG_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/SalesPackages_TAG_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/ValidableElements_TAG_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/UsageValidityPeriods_TAG_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/UserProfiles_TAG_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/GroupTickets_TAG_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/GenericParameterAssignments_TAG_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/Interchangings_TAG_formatted.xml`} mdxType="DownloadLink" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Farte (Telemark)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TEL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FareProducts_TEL_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FareStructureElements_TEL_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/SalesPackages_TEL_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/ValidableElements_TEL_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/CompanionProfiles_TEL_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FrequenciesOfUse_TEL_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/UsageValidityPeriods_TEL_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/UserProfiles_TEL_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/GroupTickets_TEL_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/GenericParameterAssignments_TEL_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/Interchangings_TEL_formatted.xml`} mdxType="DownloadLink" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`VKT (Vestfold)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`VKT`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FareProducts_VKT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FareStructureElements_VKT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/SalesPackages_VKT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/ValidableElements_VKT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/CompanionProfiles_VKT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FrequenciesOfUse_VKT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/UsageValidityPeriods_VKT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/UserProfiles_VKT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/GroupTickets_VKT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/GenericParameterAssignments_VKT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/Interchangings_VKT_formatted.xml`} mdxType="DownloadLink" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Vy Tog`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`VYT`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FareProducts_VYT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FareStructureElements_VYT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/SalesPackages_VYT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/ValidableElements_VYT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/CompanionProfiles_VYT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FrequenciesOfUse_VYT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/PenaltyPolicies_VYT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/UsageValidityPeriods_VYT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/UserProfiles_VYT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/GroupTickets_VYT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/GenericParameterAssignments_VYT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/Interchangings_VYT_formatted.xml`} mdxType="DownloadLink" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`TRO (Troms fylkestrafikk)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TRO`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FareProducts_TRO_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FareStructureElements_TRO_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/ValidableElements_TRO_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FrequenciesOfUse_TRO_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/UsageValidityPeriods_TRO_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/UserProfiles_TRO_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/GroupTickets_TRO_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/GenericParameterAssignments_TRO_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/Interchangings_TRO_formatted.xml`} mdxType="DownloadLink" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`AKT (Agder Kollektivtrafikk)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`AKT`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FareProducts_AKT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FareStructureElements_AKT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/ValidableElements_AKT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FrequenciesOfUse_AKT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/UsageValidityPeriods_AKT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/UserProfiles_AKT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/GroupTickets_AKT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/GenericParameterAssignments_AKT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/Interchangings_AKT_formatted.xml`} mdxType="DownloadLink" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`NOR (Nordland fylkeskommune)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NOR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FareProducts_NOR_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FareStructureElements_NOR_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/SalesPackages_NOR_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/ValidableElements_NOR_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/CompanionProfiles_NOR_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FrequenciesOfUse_NOR_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/UsageValidityPeriods_NOR_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/UserProfiles_NOR_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/GroupTickets_NOR_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/GenericParameterAssignments_NOR_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/Interchangings_NOR_formatted.xml`} mdxType="DownloadLink" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`OPP (Opplandstrafikk)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`OPP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FareProducts_OPP_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FareStructureElements_OPP_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/SalesPackages_OPP_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/ValidableElements_OPP_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/CompanionProfiles_OPP_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FrequenciesOfUse_OPP_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/UsageValidityPeriods_OPP_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/UserProfiles_OPP_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/GroupTickets_OPP_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/GenericParameterAssignments_OPP_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/Interchangings_OPP_formatted.xml`} mdxType="DownloadLink" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`VOT (Vestfold & Telemark)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`VOT`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FareProducts_VOT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FareStructureElements_VOT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/SalesPackages_VOT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/ValidableElements_VOT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/CompanionProfiles_VOT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/FrequenciesOfUse_VOT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/UsageValidityPeriods_VOT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/UserProfiles_VOT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/GroupTickets_VOT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/GenericParameterAssignments_VOT_formatted.xml`} mdxType="DownloadLink" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><DownloadLink href={`/static/products/Interchangings_VOT_formatted.xml`} mdxType="DownloadLink" /></td>
        </tr>
      </tbody>
    </table>
    <sub>
  * Expected to be replaced by new codespace (VYG) at a convenient opportunity.
    </sub>
    <br />
    <br />
For the time being updated approximately once a month.
    <br />
    <br />
    <a href={`/static/products/Entur-NeTEx-ProductsPricing-XML-dump.zip`} download>
  All files zipped
    </a>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      